import { Routes, Route } from "react-router-dom";
import React, { useState } from "react";

import NotFound from "./pages/NotFound";
import Login from "./Root/Login.js";
import publicRoutes from "./routes/PublicRoutes.js";
import protectedRoutes from "./routes/ProtectedRoutes";
import Context from "./Root/Context.js"; // Update the path as needed
import { TableProvider } from "../src/Context/TableContext.js"; // Import the TableProvider
import Mobile from "./Root/Mobile.js";
import { isMobile } from "react-device-detect";

// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";

// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  const [email, setEmail] = useState("");

  if (isMobile) {
    // Render only the mobile warning component for mobile users
    return <Mobile />;
  }

  return (
    <React.Fragment>
      <Context.Provider value={{ email, setEmail }}>
        <TableProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
            {protectedRoutes.map((route, index) => {
              const currentRole = localStorage.getItem("role");

              if (route.roles.includes(currentRole)) {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                );
              } else {
                return (
                  <Route key={index} path={route.path} element={<NotFound />} />
                );
              }
            })}
            {/* Add other routes as needed */}
          </Routes>
        </TableProvider>
      </Context.Provider>
    </React.Fragment>
  );
}
